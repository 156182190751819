<template>
  <!-- 背景音 -->
  <audio id="audio--bgm" :src="require('@/assets/audio/bgm.mp3')" preload autoplay muted loop></audio>  

  <!-- [Right] fixd recommend -->
  <router-link to="/winner-list"  class="scene__recommend position-fixed btn btn-link" data-aos="fade-left" data-aos-delay="500" data-aos-duration="800">
    <img src="@/assets/images/btn_winner.png" class="w-100" alt="全民票選抽大獎獲獎名單">
  </router-link>

  <!-- <Preloader/> -->
  
  <Header/>
  <div :class="{'pages': $route.name!=='index' }" :style= "[$route.name=='vote' ? {'background': 'none'} : {}]">
    <div class="container position-relative">
      <!-- share link -->
      <div class="row justify-content-center">
        <div class="col-xl-10">      
          <div :class="{'pages-link': deviceMode == 'mobile', 'd-none': ($route.name=='index' && deviceMode != 'deskTop') || $route.path.indexOf('awards')!==-1}" class="fixed-link position-absolute">
              <a class="fixed-link__icon ml-2" style="cursor: pointer;">
                <img v-if="isBgmPlay" src="@/assets/images/volume_1.png" alt="" @click.stop="isBgmPlay=!isBgmPlay; bgmPlay()">
                <img v-else src="@/assets/images/volume_0.png" alt="" @click.stop="isBgmPlay=!isBgmPlay; bgmPlay()">
              </a>
              <a v-if="$route.path.indexOf('vote')!==-1" href="https://facebook.com/sharer/sharer.php?u=https://www.2021tamsui.tw/vote/0/f" target="_blank" class="fixed-link__icon ml-2">
                <img src="@/assets/images/icon_fb.svg" alt="">
              </a>
              <a v-else :href="$route.name=='index' ? 'https://facebook.com/sharer/sharer.php?u=https://www.2021tamsui.tw/':'https://facebook.com/sharer/sharer.php?u=https://www.2021tamsui.tw/'+$route.name" target="_blank" class="fixed-link__icon ml-2">
                <img src="@/assets/images/icon_fb.svg" alt="">
              </a>
              <a v-if="$route.path.indexOf('vote')!==-1" href="https://social-plugins.line.me/lineit/share?url=https://www.2021tamsui.tw/vote" target="_blank" class="fixed-link__icon ml-2">
                <img src="@/assets/images/icon_line.svg" alt="">
              </a> 
              <a v-else :href="$route.name=='index' ? 'https://social-plugins.line.me/lineit/share?url=https://www.2021tamsui.tw/':'https://social-plugins.line.me/lineit/share?url=https://www.2021tamsui.tw/'+$route.name" target="_blank" class="fixed-link__icon ml-2">
                <img src="@/assets/images/icon_line.svg" alt="">
              </a>      
          </div>
          <div :class="{'pages-link': deviceMode == 'mobile', 'd-none': $route.path.indexOf('awards')!==1}" class="fixed-link position-absolute">
              <a class="fixed-link__icon ml-2" style="cursor: pointer;">
                <img v-if="isBgmPlay" src="@/assets/images/volume_1.png" alt="" @click="isBgmPlay=!isBgmPlay; bgmPlay()">
                <img v-else src="@/assets/images/volume_0.png" alt="" @click="isBgmPlay=!isBgmPlay; bgmPlay()">
              </a>
          </div>
        </div>
      </div>
      <Breadcrumb v-if="$route.name!=='index'" />
      <!-- <p>This is a default layout</p> -->
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
      
    </div>    
  </div>
  <Footer/>  
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Preloader from '@/components/Preloader.vue'
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  components: {
    Header,
    Footer,
    Breadcrumb,
    Preloader
  },
  data() {
		return {
      isBgmPlay: false,
    }
  },
  created() {
    AOS.init();
    document.body.classList.remove("modal-open");
  },
  setup() {
    document.querySelector('link[rel="canonical"]').setAttribute("href", window.location.href);
  },
  updated() {
    document.querySelector('link[rel="canonical"]').setAttribute("href", window.location.href);
    document.addEventListener('touchstart', (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    });
    
    let lastTouchEnd = 0;
    document.addEventListener('touchend', (event) => {
      const now = (new Date()).getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    }, false);
  },
  mounted(){
    // this.Ainit();
  },
  methods: {
    Ainit(){
      const audioBGM = document.getElementById("audio--bgm");   
      let AAA = this.isBgmPlay;   
      /*
      user與網站有互動後才可播放音樂
      https://jiepeng.me/2019/03/17/autoplay-policy-note
      */
      document.addEventListener('click', function() {
        console.log('Ainit', AAA);
        audioBGM.play();
        audioBGM.muted = !AAA; 
        audioBGM.volume = 0.65;
      });
    },
    bgmPlay(){
      const audioBGM = document.getElementById("audio--bgm");
      audioBGM.play();
      audioBGM.muted = !this.isBgmPlay;
      audioBGM.volume = 0.65;
    }
  }
}
</script>

<template>
	<span id="TOP" style="d-none"></span>
	<div v-if="deviceMode == 'deskTop'" class="container">
		<nav class="nav justify-content-center flex-nowrap">
			<span
			v-for="(item, i) in headerNav.filter(t => t.SW!=0 && ((this.ACWS && t.SW==1) || (!this.ACWS && (t.SW==2 || t.SW==1))) )" :key="item.id" 
			:class="item.name" class="nav__item"
			@mouseenter="isHover = item.name"
			@mouseleave="isHover = -1">
				
				<!-- 無子選單 -->
				<router-link v-if="!item.SubMenu" :to="{ name: item.Lock?'':item.hashPage, hash: item.hashTag }">
					<!-- 正式站使用這組區塊 -->
					<img v-if="!item.Lock && this.ACWS" :src="$route.name==item.name ? item.active : (isHover == item.name ? item.active : item.default)" :alt="'淡水八景 - '+item.alt">
					<img v-if="item.Lock && this.ACWS" @click="comingsoon()" :src="item.disable" :alt="'淡水八景 - '+item.alt">
					<!-- 測式站使用這組區塊 -->
					<img v-if="!this.ACWS" :src="$route.name==item.name ? item.active : (isHover == item.name ? item.active : item.default)" :alt="'淡水八景 - '+item.alt">
				</router-link>				

				<!-- 有子選單 -->
				<span v-if="item.SubMenu"
				@mouseenter="isHover = item.name"
				@mouseleave="isHover = -1" class="position-relative">
					<!-- 正式站使用這組區塊 -->
					<!-- <img v-if="!item.Lock && this.ACWS" :src="$route.name==item.name ? item.active : (isHover == item.name ? item.active : item.default)" :alt="'淡水八景 - '+item.alt"> -->
					<img v-if="item.Lock && this.ACWS" @click="comingsoon()" :src="item.disable" :alt="'淡水八景 - '+item.alt">
					
					<!-- 測式站使用這組區塊 -->
					<img @click="clickMenu(item.name)" :id='item.name'
					:src="item.routerName.indexOf($route.name)!==-1 ? item.active : (isHover == item.name ? item.active : item.default)" 
					:alt="'淡水八景 - '+item.alt">

					<transition name="toggle">
						<div v-if="this.showClick == item.name" class="dropdown__item" :class="{'dropdown__item--top':$route.name=='index'}">
							<ul class="list-unstyled m-0">
								<li v-for="item in headerNav[i].children" :key="item.id" @click="showToggle = false">
									<router-link :to="'/'+item.name" class="w-100 text-center">{{item.alt}}</router-link>
								</li>
							</ul>
						</div>
					</transition>

					<!-- :src="$route.name==item.name ? item.active : (isHover == item.name ? item.active : item.default)" 
					:alt="'淡水八景 - '+item.alt" -->
				</span>
			</span>

		</nav>
	</div>
	<header v-if="deviceMode != 'deskTop'" class="container d-flex align-items-start justify-content-between pt-4">
		<h1 class="logo">
			<a v-if="$route.name!='index'" href="/">
				<img src="@/assets/images/img_kv_head.png" alt="2021淡水八景4.0" class="logo__page">
			</a>
			<img v-else src="@/assets/images/img_kv_head_index.png" alt="2021淡水八景4.0" class="logo__index">
		</h1>
		<FixedLink v-if="deviceMode != 'deskTop'" />
	</header>


	<!-- modal -->
	<transition name="fade">
		<div v-if="showModal" v-bind:class="{ show: showModal }" class="modal modal__recommend">
			<div class="modal-dialog modal-dialog-centered modal-lg">
				<div class="modal-content">
					<div class="modal-header position-relative">
						<h5 class="modal-title">攝影比賽延期公告</h5>
						<button @click="showModal = false" type="button" class="close position-absolute m-0 p-0"></button>
					</div>
					<div class="modal-body row justify-content-center text-secondary">
						<div class="col-lg-9">
							<div class="text-center py-3 py-md-4">
								<p class="h5">因國內疫情影響，攝影比賽收件延後至6月30日止，以郵戳為憑。更多參賽規則請至「活動辦法」專區了解！</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
	<transition name="fade">
		<div v-if="showModal" @click="showModal = false" :class="{ show: showModal }" class="modal__backdrop"></div>
	</transition>
</template>


<script>
import FixedLink from '@/components/FixedLink.vue'
// import $ from 'jquery';

export default {
	components: {
		FixedLink
	},
	data() {
		return {
			showToggle: false,
			showClick: '',
			showModal: false,
			isHover:false,
			// SW:上架開關(0-不上架/1-上正式站/2-上正式站disable)
			// SubMenu:有無子選單
			// Lock false可點擊 true不可點擊
			headerNav: [ 
				{
					id: 1,
					name: 'mission',
					routerName: ['history', 'traffic4.0'],
					alt: '緣起',
					default: require('@/assets/images/nav_1.png'),
					active: require('@/assets/images/nav_1_active.gif'),
					disable: require('@/assets/images/nav_1.png'),
					SW: 1,
					Lock:false,
					SubMenu: true,
					hashPage: '',
					hashTag: '',
					children:[
						{
							id: 0,
							name: '8views',
							alt: '淡水繽紛八景',
							SW: 1
						},
						{
							id: 1,
							name: 'history',
							alt: '淡水八景從哪來',
							SW: 1
						},
						{
							id: 2,
							name: 'traffic4.0',
							alt: '淡水八景4.0',
							SW: 1
						},
					]
				},
				{	
					id: 2,
					name: 'intro',
					routerName:['intro'],
					alt: '活動辦法',
					default: require('@/assets/images/nav_2.png'),
					active: require('@/assets/images/nav_2_active.gif'),
					SW: 1,
					Lock:false,
					SubMenu: true,
					hashPage: '',
					hashTag: '',
					children:[
						{
							id: 0,
							name: 'intro#part1',
							alt: '全民推薦大搜密',
							SW: 1
						},
						{
							id: 1,
							name: 'intro#part2',
							alt: '絕景攝影邀請賽',
							SW: 1
						},
						{
							id: 2,
							name: 'intro#part3',
							alt: '八景4.0全民票選抽大獎',
							SW: 1
						},
					]
				},
				{
					id: 3,
					name: 'scene',
					routerName:['scene'],
					alt: '全民推薦大搜密',
					default: require('@/assets/images/nav_3.png'),
					active: require('@/assets/images/nav_3_active.gif'),
					SW: 1,
					Lock:false,
					SubMenu: false,
					hashPage: 'scene',
					hashTag:''
				},
				{
					id: 4,
					name: 'photography',
					routerName:['outstanding', 'awards'],
					alt: '絕景攝影邀請賽',
					default: require('@/assets/images/nav_4.png'),
					active: require('@/assets/images/nav_4_active.gif'),
					disable: require('@/assets/images/nav_4_disable.png'),
					SW: 1,
					Lock:false,
					SubMenu: true,
					hashPage: '',
					hashTag: '',
					children:[
						{
							id: 0,
							name: 'awards',
							alt: '得獎作品',
							SW: 1
						},
						{
							id: 1,
							name: 'outstanding',
							alt: '作品欣賞',
							SW: 1
						},
					]
				},				
				{
					id: 5,
					name: 'vote',
					routerName:['vote'],
					alt: '八景4.0全民票選抽大獎',
					default: require('@/assets/images/nav_5.png'),
					active: require('@/assets/images/nav_5_active.gif'),
					disable: require('@/assets/images/nav_5_disable.png'),
					SW: 1,
					Lock:false,
					SubMenu: false,
					hashPage: 'vote',
					hashTag:''					
				},
				{
					id: 6,
					name: 'tourist-guide',
					routerName:['tourist-guide'],
					alt: '精彩玩法路線圖',
					default: require('@/assets/images/nav_6.png'),
					active: require('@/assets/images/nav_6_active.gif'),
					SW: 1,
					Lock:false,
					SubMenu: false,
					hashPage: 'tourist-guide',
					hashTag:''
				},
				{
					id: 7,
					name: 'sponsor',
					routerName:['sponsor'],
					alt: '贊助商列表',
					default: require('@/assets/images/nav_7.png'),
					active: require('@/assets/images/nav_7_active.png'),
					SW: 1,
					Lock:false,
					SubMenu: false,
					hashPage: 'sponsor',
					hashTag:''
				},
			],
		}
	},
	watch: {
		showModal() {
			this.showModal ? document.body.classList.add("modal-open") : document.body.classList.remove("modal-open");
		}
	},
	mounted() {
        document.addEventListener('click', this.handleClickaway);
		this.showModal ? document.body.classList.add("modal-open") : document.body.classList.remove("modal-open");
		//Part2結束時間
		var current = new Date();    
		var endPart2 = new Date("Jun 30, 2021 23:59:59")
		if(current.getTime()<endPart2.getTime()){
			this.showModal = true;
		}
    },
	beforeUnmount() {
		document.removeEventListener('click', this.handleClickaway);
    },
	methods: {
		comingsoon: function () {
			alert('活動即將開始，敬請期待！'); 
		},
		clickMenu(name){
			this.showClick = name;
			this.showToggle = true;
		},
		handleClickaway(e) {			
			if(e.target.id!=this.showClick){
				this.showClick='';
			}
			//console.log(e.target.id,this.showClick);
			//console.log(this.itemRefs, e.target,'______',this.itemRefs[0].contains(e.target),this.itemRefs[1].contains(e.target));
        }
	}
}
</script>
<template>
  <footer class="footer text-center mt-auto" :class="{ 'position-relative': $route.name=='index' && deviceMode != 'deskTop'}">
    <!-- <footer class="text-center mt-auto"> -->

    <!-- 置底link -->
    <div v-if="$route.path.indexOf('awards')==-1 || deviceMode=='mobile'" class="container position-relative">
      <!-- 小貓 -->
      <div class="enjoyToggle position-absolute" v-if="$route.name!='index'" @click="isEnjoy = !isEnjoy; ClickEnjoy=false;">
        <img :src="isEnjoy ? require('@/assets/images/footer_toggle_2.png') : require('@/assets/images/footer_toggle_1.png')" alt="" class="w-100">
      </div>

      <div class="footer__nav d-flex justify-content-center flex-nowrap py-1 py-md-2 pb-md-2" :class="{'active': isEnjoy || $route.name=='index'}">
        <router-link v-for="item in footerNavImg" :to="'/'+item.name" class="mx-md-2" :key="item.id" 
          @mouseenter="isHover = item.name" @mouseleave="isHover = -1">
          <img :src="$route.name==item.name ? item.active : isHover == item.name ? item.active : item.default" :alt="'淡水八景 - '+item.alt">
        </router-link>
      </div>
    </div>

    <div class="co-organiser py-2 text-white">
      <div class="container">
        <ul class="list-inline font-weight-bold mb-0">
          <li class="list-inline-item mr-0 mr-md-3 mx-md-3 mb-0">主辦單位：新北市淡水區公所</li>
          <li class="list-inline-item mr-0 mr-md-3 mx-md-3 mb-0">承辦單位：財團法人慈暉文教基金會</li>
          <li class="list-inline-item mr-0 mx-md-3 mb-0">協辦單位：
            <span class="co_organiser_list">
              <span v-for="(item, index) in coOrganiser" :key="index" :class="'letters letters-'+(index+1)">{{item.Name}}</span>
            </span>
          </li>
        </ul>
        <address class="mb-0">
          <span class="d-lg-inline-block">©淡水區公所、財團法人慈暉文教基金會版權所有</span></address>
      </div>
    </div>
    <svg class="editorial"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28"
      preserveAspectRatio="none">
      <defs>
      <path id="gentle-wave"
      d="M-160 44c30 0 
          58-10 88-10s
          58 10 88 10 
          58-10 88-10 
          58 10 88 10
          v44h-352z" />
        </defs>
      <g class="wave">
        <use xlink:href="#gentle-wave" x="50" y="0" fill="#00a0b0"/>
      </g>
    </svg>

    <!-- 樹 -->
    <img v-if="$route.path.indexOf('awards')!==-1" src="@/assets/images/img_footer_tree1.png" alt="" class="position-absolute tree">
    <img v-if="$route.path.indexOf('awards')!==-1" src="@/assets/images/img_footer_tree2.png" alt="" class="position-absolute tree">
  </footer>
</template>


<script>
import $ from 'jquery';
import anime from 'animejs/lib/anime.es.js';

var last = 0;
export default {
  components: {
  },
  data() {
    return {
      coOrganiser: [],
      isHover: 0,
      isEnjoy: true,
      ClickEnjoy: true,
      footerNavImg: [
        {
          id: 0,
          name: 'eat',
          alt: '吃什麼',
          default: require('@/assets/images/nav_footer_1.png'),
					active: require('@/assets/images/nav_footer_1_h.png'),
        },
        {
          id: 1,
          name: 'play',
          alt: '玩什麼',
          default: require('@/assets/images/nav_footer_2.png'),
					active: require('@/assets/images/nav_footer_2_h.png'),
        },
        {
          id: 2,
          name: 'stay',
          alt: '住哪裡',
          default: require('@/assets/images/nav_footer_3.png'),
					active: require('@/assets/images/nav_footer_3_h.png'),
        },
        {
          id: 3,
          name: 'buy',
          alt: '歐咪呀給哪裡買',
          default: require('@/assets/images/nav_footer_4.png'),
					active: require('@/assets/images/nav_footer_4_h.png'),
        },
      ],
    }
  },
  /* Init */
  created() {
    this.CoOrganiser();
  },
  updated(){
    var T=this;
    $(window).scroll(function(){
      if(T.ClickEnjoy!=false){
        var Pos = $(this).scrollTop();
        if(Pos > last && window.location.href.split("/")[3] !== ""){
          T.isEnjoy=false;
        }else if(Pos < last && window.location.href.split("/")[3] !== ""){
          T.isEnjoy=true;
        }
        last = Pos;
      }
    });
  },
  /* Function */
  methods: {
    CoOrganiser(){
      var CO = new FormData();
      var URL = location.href;

      CO.append('mode', 'sct');
      if(URL.indexOf("dev")!=-1 || URL.indexOf("localhost")!=-1){
        CO.append('data', '{"class":"co-organiser","lang":"zh-TW"}');
      } else{
        CO.append('data', '{"class":"co-organiser","lang":"zh-TW","sw":1}');
      }

      this.axios({
          method: 'post',
          url: 'RApi',
          data: CO,
          headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(response => {
        this.coOrganiser=response.data.data;
        setTimeout(function(){
          var j=2,j_s=1,c_j='.letters-';
          anime.timeline().add({targets: c_j+'1',opacity: 1})
          setInterval(function(){
            if(j_s==0){anime.timeline().add({targets: c_j+response.data.data.length,opacity: 0,easing: "linear",duration: 800})}
            anime.timeline().add({targets: c_j+(j_s++),opacity: 0,easing: "linear",duration: 500})
            anime.timeline().add({targets: c_j+(j++),opacity: [0,1],easing: "linear",duration: 500})
            if(j_s>=response.data.data.length){j=1;j_s=0;}
          }, 5000);
        },120);

      })
      .catch(function (error) {
          console.log(error);
      });
    }, 
  }
}
</script>
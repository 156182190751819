<template>
    <div id="preloader">
        <UploadLoading class="circle position-absolute"/>
        <svg class="editorial"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none">
            <defs>
            <path id="gentle-wave"
            d="M-160 44c30 0 
                58-10 88-10s
                58 10 88 10 
                58-10 88-10 
                58 10 88 10
                v44h-352z" />
                </defs>
            <g class="wave">
                <use xlink:href="#gentle-wave" x="50" y="0" fill="#00a0b0"/>
            </g>
        </svg>
    </div>
</template>

<script>
import UploadLoading from '@/components/UploadLoading.vue'
import { onMounted, onUnmounted } from 'vue';
import $ from 'jquery'

export default {
  components: {
    UploadLoading
  },
  setup() {
    onMounted(() => {
        $('body').css('background-color','#eed84d');
        function Preloader() {
            $( "#preloader" ).stop(true, true).delay(1500).animate({ "top": "90vh" }, 1000, "linear" ).animate({ "top": "100vh", "z-index": "0","opacity": "0" }, 200, "linear");
            $( ".circle" ).stop(true, true).delay(1500).hide(10);
        }
        if ( !sessionStorage.getItem( 'doNotShow' ) ) {
            sessionStorage.setItem( 'doNotShow', true );
            Preloader();
        } else {
            $ ('#preloader, .circle').hide();
        }
    })
    onUnmounted(() => {})
  }
}
</script>
<template>
  <!-- mobile icon -->
  <div v-if="deviceMode != 'deskTop'" class="fixed-link">
      <a v-if="$route.name=='index'" class="fixed-link__icon ml-2" style="cursor: pointer;">
        <img v-if="isBgmPlay" src="@/assets/images/volume_1.png" alt="" @click="isBgmPlay=!isBgmPlay; bgmPlay()">
        <img v-else src="@/assets/images/volume_0.png" alt="" @click="isBgmPlay=!isBgmPlay; bgmPlay()">
      </a>
      <a v-if="$route.name=='index'" :href="'https://facebook.com/sharer/sharer.php?u=https://www.2021tamsui.tw/'" class="fixed-link__icon ml-2">
        <img src="@/assets/images/icon_fb.svg" alt="">
      </a>
      <a v-if="$route.name=='index'" :href="'https://social-plugins.line.me/lineit/share?url=https://www.2021tamsui.tw/'" class="fixed-link__icon ml-2">
        <img src="@/assets/images/icon_line.svg" alt="">
      </a>
      <button v-if="$route.name!='index' && deviceMode != 'deskTop'" key="on" @click="showMenuModal = true" class="fixed-link__icon ml-2 p-0">
        <img src="@/assets/images/icon_hanburger.svg" alt="">
      </button>
  </div>
  
  <!-- 漢堡選單展開 -->
  <transition name="fade">
    <div v-if="showMenuModal" v-bind:class="{ show: showMenuModal }" class="modal__nav position-fixed d-flex flex-column justify-content-between">
      <div class="modal__nav__head container pt-4">
        <a href="/"><img src="@/assets/images/img_kv_head_w.png" alt="" class="logo__page"></a>
        <button key="off" @click="showMenuModal = false" type="button" class="close position-absolute m-0 p-0"></button>
      </div>
      <div class="modal__nav__content flex-shrink-1">
        <ul class="text-white text-center list-unstyled my-2">
          <li @click="showClick = 'mission'; showToggle=!showToggle" :class="(showClick == 'mission'&&showToggle ? 'modal__subNav' : '')">緣起 <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <polygon points="11.63 5.19 8.01 8.44 4.37 5.18 3.2 6.51 8.01 10.81 12.8 6.51 11.63 5.19"/>
            </svg></li>
          <transition name="toggle">
            <li v-if="showClick == 'mission' && showToggle" :class="{'modal__subNav': showClick == 'mission'}" @click="showClick = '';showMenuModal = false">
              <router-link to="/history" class="d-block text-white py-1 py-md-2">淡水八景從哪來</router-link>
              <router-link to="/traffic4.0" class="d-block text-white py-1 py-md-2">淡水八景4.0</router-link>
            </li>
          </transition>

          <li @click="showClick = 'intro'; showToggle=!showToggle" :class="(showClick == 'intro'&&showToggle ? 'modal__subNav' : '')">活動辦法 <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <polygon points="11.63 5.19 8.01 8.44 4.37 5.18 3.2 6.51 8.01 10.81 12.8 6.51 11.63 5.19"/>
            </svg></li>
          <transition name="toggle">
            <li v-if="showClick == 'intro' && showToggle" :class="{'modal__subNav': showClick == 'intro'}" @click="showClick = '';showMenuModal = false">
              <router-link to="/intro#part1" class="d-block text-white py-1 py-md-2">全民推薦大搜密</router-link>
              <router-link to="/intro#part2" class="d-block text-white py-1 py-md-2">絕景攝影邀請賽</router-link>
              <router-link to="/intro#part3" class="d-block text-white py-1 py-md-2">八景4.0全民票選抽大獎</router-link>
            </li>
          </transition>
          
          <li><router-link class="text-white" to="/scene" @click="showMenuModal = false">全民推薦大搜密  2/20-3/31</router-link></li>
          
          <li @click="showClick = 'photo'; showToggle=!showToggle" :class="(showClick == 'photo'&&showToggle ? 'modal__subNav' : '')">絕景攝影邀請賽  4/21-6/30 <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <polygon points="11.63 5.19 8.01 8.44 4.37 5.18 3.2 6.51 8.01 10.81 12.8 6.51 11.63 5.19"/>
            </svg></li>
          <transition name="toggle">
            <li v-if="showClick == 'photo' && showToggle" :class="{'modal__subNav': showClick == 'photo'}" @click="showClick = '';showMenuModal = false">
              <router-link to="/awards" class="d-block text-white py-1 py-md-2">得獎作品</router-link>
              <router-link to="/outstanding" class="d-block text-white py-1 py-md-2">作品欣賞</router-link>
            </li>
          </transition>
          <li><router-link class="text-white" to="/vote" @click="showMenuModal = false">八景4.0全民票選抽大獎  8/05-9/30</router-link></li>
          <li><router-link class="text-white" to="/tourist-guide" @click="showMenuModal = false">SHOW TIME 精彩玩法路線圖</router-link></li>
          <li><router-link class="text-white" to="/sponsor" @click="showMenuModal = false">贊助商列表</router-link></li>
        </ul>
        <div v-if="deviceMode == 'mobile'" class="modal__nav__foot text-center">
          <img class="" src="@/assets/images/img_modalnav.png" alt="">
        </div>
      </div>
      <div v-if="deviceMode != 'mobile'" class="modal__nav__foot text-center">
        <img class="" src="@/assets/images/img_modalnav.png" alt="">
      </div>
    </div>
  </transition>  

  <!-- 返回頂部 -->
  <div class="goTop">
    <img src="@/assets/images/btn_gotop.png" alt="" class="w-100">
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  data() {
    return { 
      showMenuModal: false,
      showClick: '',
      showToggle: false,
      showModal: false,
      isBgmPlay: false
    }
  },
  updated(){
    $(window).scroll(function(){
      if($(window).scrollTop()>=1000){
          $(".goTop").fadeIn(300).click(function() {
              $('html, body').stop(true, false).animate({
                  scrollTop: 0
              }, 'slow');
              return false;
          });
      } else{
          $(".goTop").fadeOut(300);
      }
    });
  },
  watch: {
    showMenuModal: function () {
      this.showMenuModal ? document.body.classList.add("modal-open") : document.body.classList.remove("modal-open");
    }
  },
  methods: {
    comingsoon: function () {
      alert('活動即將開始，敬請期待！'); 
    },
    bgmPlay(){
      const audioBGM = document.getElementById("audio--bgm");
      audioBGM.play();
      audioBGM.muted = !this.isBgmPlay;
      audioBGM.volume = 0.65;
    }
  }
}
</script>

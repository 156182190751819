<template>
  <div class="row justify-content-center">
      <div class="col-xl-10 breadcrumb bg-transparent mb-0 mb-sm-2 py-md-0">
        <div class="fixed-link__icon text-secondary d-flex">
            <img src="@/assets/images/icon_home.svg" alt="首頁" class="icon__home mr-1">
        </div>
        <span v-for="(item,index) in list" :key="index" class="breadcrumb-item">
          <router-link :to="{path:item.path}" class="text-secondary">{{item.meta.title}}
            <!-- <span v-if="index < list.length-1">/ </span> 避免在导航最后出现分割线 -->
          </router-link>
        </span> 
      </div>
  </div>
  
</template>

<script>
import { reactive, toRefs, onMounted, watch } from "vue";
import { useRoute } from 'vue-router';
export default {
  data () {
    return {}
  },
  setup() {
    //data
    const route = useRoute();
    const state = reactive({
      list:[]
    });
    
    //onMounted
    onMounted(()=>{
      let matched = route.matched;//获取菜单对应的路由信息
      state.list = matched;
    })
    
    //watch
    watch(()=>route.matched,(newVal)=>{
      let matched = newVal;
      state.list = matched;//更新路由菜单数组
      // console.log(newVal,oldVal);
    })
    
    //return
    return {
      ...toRefs(state), //toRefs() 将数据变成响应式数据
    };
  },  
}
</script>